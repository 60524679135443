import { useOpenID } from "../openid/provider";

export const IdToken = () => {
  const { decoded_id_token } = useOpenID();
  if (decoded_id_token === null) return <></>;
  return (
    <div data-qa="id_token--payload">
      <div>
        <h3>id_token</h3>
        <pre>{JSON.stringify(decoded_id_token, null, 2)}</pre>
      </div>
    </div>
  );
};
