import { useOpenID } from "../openid/provider";

export const AccessToken = () => {
  const { decoded_access_token } = useOpenID();
  if (decoded_access_token === null) return <></>;

  return (
    <div data-qa="access_token--payload">
      <div>
        <h3>access_token</h3>
        <pre>{JSON.stringify(decoded_access_token, null, 2)}</pre>
      </div>
    </div>
  );
};
